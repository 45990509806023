import React from "react"
import Layout from "../components/Layout"

const Welcome = () => {
  return (
    <Layout>
      <div className="welcome__picture" />
      <section className="welcome__section">
        <div className="blurb">
          <h1>We Are Called to Welcome</h1>
          <div className="welcome__video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/videoseries?list=PLpNioTLvm4rqL8vDfEw-u971kha1Dtd0j"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
        <div className="blurb">
          <p>
            From beginning to end, the Bible is full of stories of people on the
            move - stories of immigrants and refugees.
          </p>
          <p>
            Throughout Scripture God calls his people to &lsquo;welcome the
            stranger.&rsquo; Jesus even says that in welcoming the stranger we
            welcome Christ himself (Mt 25:35-40).
          </p>
          <p>
            God loves the vulnerable foreigner and is always at work inviting
            them into His kingdom. As followers of Jesus, we have the
            responsibility, and the joy, of welcoming and embracing refugees
            into our communities.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Welcome
